<template>
  <div class="wrapper">
    <headBack>
      <template v-slot:title>
        <div class='title'>
          订单物流
        </div>
      </template>
    </headBack>
    <div class='header_cont'></div>
    <div class="wl_box">
      <div class="list">发货时间 ：  <span class="txt">{{detail.deliverTimeDesc}}</span></div>
      <div class="list">快递公司 ：  <span  class="txt">{{detail.courierServicesCompany}}</span></div>
      <div class="dhao list"><div>快递单号 ：  <span  class="txt">{{ detail.courierServicesOrder }}</span></div> <div class="copy" @click="copy(detail.courierServicesOrder)">复制</div> </div>
    </div>
  </div>
</template>

<script>
import {setWxShareDate} from '@/libs/utils.js'
import {onMounted, ref, computed, reactive, watch} from "vue"
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import {Dialog,Toast} from 'vant'
import shopApi from '@axios/shop'
import loginApi from '@axios/login'
export default {
  name: "logistics",
  setup(){
    const route = useRoute();

    const detail = ref('')
    const getDetail = ()=> {
      const params = {
         orderId : route.query.orderId
      }
      shopApi.getOrderLogistics(params).then((res) => {
        console.log(res)
        detail.value = res.data;
      })
    }
    getDetail();

    const copy= (txt)=> {
      let input = document.createElement("input"); // 创建input对象
      input.value = txt; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例

      Toast("复制成功")
    }
    return {
      detail,
      copy
    }
  }
}
</script>

<style scoped lang="scss">
  .wl_box{
    border-radius: 16px;
    font-size: 28px;
    box-sizing: border-box;
    margin:28px auto;
    width:94%;
    color:#878888;
    background: #fafafa;
    padding:20px;
    .list{
      margin-bottom: 14px;
    }
    .txt{
      color:#1d1e1e;
    }
    .dhao{
      display: flex;
      justify-content: space-between;
      .copy{
        color:#1d1e1e;
        font-size: 20px;
        border-radius: 18px;
        padding:1px 10px;
        background: #f2f3f3;
      }
    }
  }
</style>
