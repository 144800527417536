import { render } from "./logistics.vue?vue&type=template&id=f07ba5d2&scoped=true"
import script from "./logistics.vue?vue&type=script&lang=js"
export * from "./logistics.vue?vue&type=script&lang=js"

import "./logistics.vue?vue&type=style&index=0&id=f07ba5d2&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-f07ba5d2"
/* hot reload */
if (module.hot) {
  script.__hmrId = "f07ba5d2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f07ba5d2', script)) {
    api.reload('f07ba5d2', script)
  }
  
  module.hot.accept("./logistics.vue?vue&type=template&id=f07ba5d2&scoped=true", () => {
    api.rerender('f07ba5d2', render)
  })

}

script.__file = "src/views/shop/order/logistics/logistics.vue"

export default script